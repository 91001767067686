<template>
  <v-row no-gutters align="start" class="mb-n15">
    <v-col cols="2">
      <v-card flat class="containerBorder2" height="70">
        <v-row no-gutters>
          <v-col align="start">
            <v-card-text class="text-h4 primary--text" v-if="$i18n === 'en'">
              {{ $t("header") }}
            </v-card-text>
            <v-card-text class="text-h4 primary--text" v-else>
              {{ $t("header") }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <v-list dark color="primary" flat height="730" class="pa-0">
        <v-list-item
          v-for="floor in $t('floors')"
          :key="floor.floorNumber"
          class="pt-0"
        >
          <v-list-item-content color="primary">
            <span
              class="text-h5 d-flex justify-center font-weight-bold"
              @click="showMaps(floor.floorNumber)"
            >
              {{ floor.floorName }}
              <v-spacer></v-spacer>
              <v-icon v-if="showList && floor.floorNumber === floorNum">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else> mdi-chevron-down </v-icon>
            </span>

            <v-list
              dark
              color="primary"
              flat
              v-if="floor.floorNumber === floorNum && showList"
            >
              <v-list-item-group class="mt-2" color="white">
                <v-virtual-scroll
                  class="scrollClass"
                  bench="0"
                  :items="maps"
                  item-height="64"
                  height="550"
                >
                  <template v-slot:default="{ item }">
                    <v-divider color="white"></v-divider>
                    <v-list-item
                      color="secondary"
                      @click="
                        selectImage(
                          item.mapImage,
                          item.mapFloor,
                          item.speech,
                          item.mapName
                        )
                      "
                    >
                      <v-list-item-content>
                        <span class="white--text text-h6 font-weight-regular">
                          {{ item.mapName | trimLength }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-list-item-group>
            </v-list>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="10">
      <v-tabs
        active-class="tabClass"
        background-color="primary"
        centered
        height="70"
        dark
        v-model="active_tab"
      >
        <v-tab
          v-for="(floor, index) in $t('floors')"
          :key="index"
          @click="
            selectImage(
              floor.floorImage.url,
              floor.floorNum,
              floor.speech,
              floor.floorName
            )
          "
          class="text-h5 pa-5"
        >
          {{ floor.floorName }}
        </v-tab>
      </v-tabs>
      <v-row>
        <v-col align="center">
          <v-img :src="url" width="79%"> </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "maps",
  data() {
    return {
      active_tab: 1,
      url: "",
      floorNum: null,
      showList: false,
      count: 0,
    };
  },
  computed: {
    ...mapGetters(["getMaps", "getCMSlink"]),
    maps() {
      let temp = [];
      this.$i18n.t("maps").forEach((map) => {
        if (map.mapFloor === this.floorNum) {
          temp.push(map);
        }
      });
      return temp;
    },
  },
  methods: {
    selectImage(mapPath, mapFloor, speech, mapName) {
      this.active_tab = mapFloor - 1;
      this.url = mapPath;

      // Condition added to avoid redundant call from Mounted
      if (speech !== undefined) {
        this.$store.dispatch("avatarSpeak", speech);
        // Touch Usage
        this.$store.commit("setTouchRequest", {
          module: "Maps and Directory",
          action: "View Map",
          response: mapName,
          timeStamp: new Date(),
          requestType: "Touch",
        });
      }
    },
    showMaps(num) {
      if (this.floorNum === num) {
        this.showList = false;
        this.floorNum = 0;
      } else {
        this.floorNum = num;
        this.showList = true;
      }
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 20) {
        return val;
      }
      return `${val.substring(0, 20)}...`;
    },
  },
  mounted() {
    this.selectImage(
      this.$i18n.t("floors")[0].floorImage.url,
      this.$i18n.t("floors")[0].floorNum
    );
  },
};
</script>
<i18n>
{
  "en": {
      "header": "Map Directory"
    },
  "es":{
      "header": "Directorio Mapas"
    }
}
</i18n>
<style scoped>
.containerBorder2 {
  border-radius: 0px !important;
}
</style>