import * as fb from "@/firebase/index.js";
import axios from 'axios';
import { ApolloClient } from 'apollo-client';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

function today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return (mm + '-' + dd + '-' + yyyy);
}

export default {
    state: {
        dataLoaded: false,
        apolloClient: undefined,
    },
    getters: {
        getDataLoaded: state => state.dataLoaded,
        getApolloClient: state => state.apolloClient
    },
    mutations: {
        setDataLoaded(state, status) {
            state.dataLoaded = status;
        },
        setApolloClient(state, uri) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [],
                    },
                },
            });
            state.apolloClient = new ApolloClient({
                link: new HttpLink({ uri: uri }),
                cache: new InMemoryCache({ fragmentMatcher })
            })
        },
    },
    actions: {
        createApolloConnection({ commit }, uri) {
            return new Promise((resolve, reject) => {
                if (uri === '' || uri === null) reject('Empty or Invalid URI')
                try {
                    commit('setApolloClient', uri)
                    resolve('Apollo linked successfully');
                } catch (err) {
                    reject(err.message);
                }
            })
        },


        // -----------------------------  Fetch Homepages --------------------------------------

        fetchHomePage({ dispatch }) {

            return new Promise((resolve, reject) => {
                dispatch('fetchHomePageEnglish').then(() => {
                    dispatch('fetchHomePageSpanish').then(() => {
                        console.log('Home page data loaded successfully')
                    })
                })
                resolve('Homepage Data feteched successfully')
                error => {
                    reject(error.message)
                }
            })
        },

        // ----------------------------- Fetch Faqs --------------------------------------

        fetchFaqs({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('fetchFaqsEnglish').then(() => {
                    dispatch('fetchFaqsSpanish').then(() => {
                        resolve('Faqs Data feteched successfully ')
                    }),
                        error => {
                            reject(error.message)
                        }
                })
            })
        },

        fetchFaqCategory({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('fetchFaqCategoryEnglish').then(() => {
                    dispatch('fetchFaqCategorySpanish').then(() => {
                        resolve('Faq Category Data feteched successfully')
                    }),
                        error => {
                            reject(error.message)
                        }
                })
            })
        },

        // ----------------------------- Fetch Maps --------------------------------------

        fetchFloors({ dispatch, getters }) {
            console.log('Coming in fetch floors')
            console.log(getters.getKioskProfile.data())
            return new Promise((resolve, reject) => {
                dispatch('fetchFloorsEnglish').then(() => {
                    dispatch('fetchFloorsSpanish').then(() => {
                        resolve('Floors Data feteched successfully ')
                    }),
                        error => {
                            reject(error.message)
                        }
                })
            })
        },

        fetchMaps({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('fetchMapsEnglish').then(() => {
                    dispatch('fetchMapsSpanish').then(() => {
                        resolve('Maps Data feteched successfully ')
                    }),
                        error => {
                            reject(error.message)
                        }
                })
            })
        },

        fetchDepartments({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('fetchDepartmentsEnglish').then(() => {
                    dispatch('fetchDepartmentsSpanish').then(() => {
                        resolve('Departments Data feteched successfully ')
                    }),
                    // resolve('Departments Data feteched successfully ')
                    error => {
                        reject(error.message)
                    }
                })
            })
        },
        fetchOnlineServices({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('fetchOnlineServicesEnglish').then(() => {
                    dispatch('fetchOnlineServicesSpanish').then(() => {
                        resolve('Departments Data feteched successfully ')
                    }),
                    resolve('Online Services Data feteched successfully ')
                    error => {
                        reject(error.message)
                    }
                })
            })
        },

        saveInCache({ state, getters }, path) {
            return new Promise((response, reject) => {
                state.fileCache = caches.open('fileCache')
                    .then(cache => {
                        cache.match(getters.getCMSlink + path)
                            .then(cacheResponse => {
                                if (cacheResponse) {
                                    //console.log(`${path} FOUND in Cache`)
                                    return (cacheResponse.blob())
                                        .then(blob => {
                                            response(URL.createObjectURL(blob))
                                        })
                                } else {
                                    //console.log(`${path} ADDED in Cache`)
                                    cache.add(getters.getCMSlink + path).then(() => {
                                        cache.match(getters.getCMSlink + path)
                                            .then(cacheResponse => {
                                                return (cacheResponse.blob())
                                                    .then(blob => {
                                                        response(URL.createObjectURL(blob))
                                                    })
                                            })
                                    })
                                }
                            })
                    })
            })
        },
        searchOdysseybyName({ state }, keyword) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "taos")
                    .where("dateCreated", "==", today())
                    .where("partyOneName", "==", keyword.toLowerCase())
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry! I could not find any results for the entered name.')
                        response(querySnapshot);
                    })
            })
        },
        searchOdysseybyCaseNo({ state }, caseNumber) {
            return new Promise((response, reject) => {
                fb.odysseyCollection
                    .where("courtLocation", "==", "taos")
                    .where("dateCreated", "==", today())
                    .where("caseNo", "==", caseNumber)
                    .get()
                    .then(querySnapshot => {
                        if (querySnapshot.empty) reject('Sorry! I could not find any results for the entered case number.')
                        response(querySnapshot);
                    })

            })
        },

        searchByCaseNo({ state }, caseNumber) {
            return new Promise((response, reject) => {
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
                    params: {
                        caseNumber: caseNumber,
                        courtCity: 'taos'
                    }
                };

                axios(config)
                    .then(result => {
                        //   console.log('DATA X CHANGE: ', result.data)
                        response(result.data);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        reject(error)
                    });

            })
        },
        searchByName({ state }, nameObject) {
            return new Promise((response, reject) => {
                let parameter;
                if (nameObject.middleName === '' || nameObject.middleName === null) {
                    parameter = {
                        firstName: (nameObject.firstName.toLowerCase()).trim(),
                        lastName: (nameObject.lastName.toLowerCase()).trim(),
                        courtCity: 'taos'
                    }
                }
                else {
                    parameter = {
                        firstName: (nameObject.firstName.toLowerCase()).trim(),
                        lastName: (nameObject.lastName.toLowerCase()).trim(),
                        middleName: (nameObject.middleName.toLowerCase()).trim(),
                        courtCity: 'taos'
                    }
                }
                let config = {
                    method: 'get',
                    url: 'https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/caseSearch/a763cd056f1b2405788443b7197e0708',
                    params: parameter
                };

                axios(config)
                    .then(result => {
                        console.log('DATA X CHANGE: ', result.data)
                        response(result.data);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        reject(error)
                    });

            })
        }
    }
}
