<template>
  <v-dialog
    v-model="getEmailViewer.show"
    width="40%"
    @click:outside="closeDialog('Close')"
  >
    <v-card class="pa-5 containerBorder">
      <v-row class="mb-2">
        <v-col cols="11" align="center">
          <span class="text-h5 font-weight-bold">
            Link to {{ getEmailViewer.pdfName }}</span
          >
        </v-col>
        <v-col cols="1" align="right">
          <v-icon dark large color="secondary" @click="closeDialog('Close')"
            >mdi-close</v-icon
          >
        </v-col>
      </v-row>
      <v-form ref="form">
        <v-row justify="center" no-gutters class="fill-height">
          <v-col cols="10">
            <v-text-field
              required
              placeholder="username@domain.com"
              outlined
              :rules="emailRules"
              v-model="emailAddress"
              class="ml-3 text-h5"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" align-self="start">
            <v-btn
              x-large
              color="primary"
              class="ml-4"
              @click="closeDialog('Send')"
            >
              {{ $t("sendbutton") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="">
            <SimpleKeyboard
              keyboardClass="email-keyboard"
              @onChange="onChange"
              @onKeyPress="onKeyPress"
              :input="emailAddress"
            >
            </SimpleKeyboard>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import { mapGetters } from "vuex";

export default {
  name: "emailViewer",
  data() {
    return {
      emailAddress: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      emailText: "",
      pdfUrl: "",
      pdfName: "",
    };
  },
  components: {
    SimpleKeyboard,
  },
  computed: {
    ...mapGetters(["getEmailViewer"]),
  },
  methods: {
    onChange(input) {
      this.emailAddress = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}") {
        this.emailAddress = this.emailAddress.slice(0, -1);
      }
    },
    closeDialog(action) {
      if (action === "Send") {
        switch (this.getEmailViewer.pdfType.toLowerCase()) {
          case "link":
            this.emailText =
              "Following is the link to the Digital forms requested,";
            this.pdfUrl = this.getEmailViewer.pdfUrl;
            break;
          default:
            break;
        }
        if (this.emailAddress === "") {
          this.$store.dispatch("avatarSpeak", this.$i18n.t("noemail"));
        } else {
          let validate = this.$refs.form.validate();
          if (validate) {
            this.$store
              .dispatch("sendEmail", {
                address: this.emailAddress,
                subject: "Ottawa County",
                content:
                  "Hello," +
                  "<br>" +
                  "<br>" +
                  this.emailText +
                  "<br>" +
                  "<br>" +
                  this.pdfUrl +
                  "<br>" +
                  "<br> Thank you," +
                  "<br><strong>Team Francesca</strong>",
              })
              .then(
                (response) => {
                  console.log(response);
                  this.$store.commit("closeEmailViewer");
                  this.$store.dispatch(
                    "avatarSpeak",
                    this.$i18n.t("successemailresponse")
                  );
                  this.emailAddress = "";
                  // Touch usage
                  this.$store.commit("setTouchRequest", {
                    module: "Email Viewer",
                    action: "Email PDF",
                    response: this.pdfName,
                    timeStamp: new Date(),
                    requestType: "Email",
                  });
                },
                (error) => {
                  console.log(error);
                  this.$store.dispatch(
                    "avatarSpeak",
                    this.$i18n.t("erroremailresponse")
                  );
                  this.emailAddress = "";
                }
              );
          } else {
            this.$store.dispatch("avatarSpeak", this.$i18n.t("invalidemail"));
          }
        }
      } else if (action === "Close") {
        this.$store.commit("closeEmailViewer");
      }
    },
  },
};
</script>

<style scoped>
</style>
<i18n>
{
  "en": {
      "sendbutton": "Send",
      "successemailresponse": "Got it! you will receive the email shortly.",
      "erroremailresponse": "Oops! There is some problem with email service. Try again later.",
      "noemail": "Email ID cannot be empty.",
      "invalidemail": "Please enter a valid Email ID."
    },
  "es":{
      "sendbutton": "Enviar",
      "successemailresponse": "¡Entiendo! recibirá el correo electrónico en breve.",
      "erroremailresponse": "Oops! Hay algún problema con el servicio de correo electrónico. Vuelve a intentarlo más tarde.",
      "noemail": "El ID de correo electrónico no puede estar vacío.",
      "invalidemail": "Ingrese un ID de correo electrónico válido."
    }
}
</i18n>