var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mb-n15",attrs:{"no-gutters":"","align":"start"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',{staticClass:"containerBorder2",attrs:{"flat":"","height":"70"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"start"}},[(_vm.$i18n === 'en')?_c('v-card-text',{staticClass:"text-h4 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("header"))+" ")]):_c('v-card-text',{staticClass:"text-h4 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("header"))+" ")])],1)],1)],1),_c('v-list',{staticClass:"pa-0",attrs:{"dark":"","color":"primary","flat":"","height":"730"}},_vm._l((_vm.$t('floors')),function(floor){return _c('v-list-item',{key:floor.floorNumber,staticClass:"pt-0"},[_c('v-list-item-content',{attrs:{"color":"primary"}},[_c('span',{staticClass:"text-h5 d-flex justify-center font-weight-bold",on:{"click":function($event){return _vm.showMaps(floor.floorNumber)}}},[_vm._v(" "+_vm._s(floor.floorName)+" "),_c('v-spacer'),(_vm.showList && floor.floorNumber === _vm.floorNum)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1),(floor.floorNumber === _vm.floorNum && _vm.showList)?_c('v-list',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-list-item-group',{staticClass:"mt-2",attrs:{"color":"white"}},[_c('v-virtual-scroll',{staticClass:"scrollClass",attrs:{"bench":"0","items":_vm.maps,"item-height":"64","height":"550"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-divider',{attrs:{"color":"white"}}),_c('v-list-item',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.selectImage(
                        item.mapImage,
                        item.mapFloor,
                        item.speech,
                        item.mapName
                      )}}},[_c('v-list-item-content',[_c('span',{staticClass:"white--text text-h6 font-weight-regular"},[_vm._v(" "+_vm._s(_vm._f("trimLength")(item.mapName))+" ")])])],1)]}}],null,true)})],1)],1):_vm._e()],1)],1)}),1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-tabs',{attrs:{"active-class":"tabClass","background-color":"primary","centered":"","height":"70","dark":""},model:{value:(_vm.active_tab),callback:function ($$v) {_vm.active_tab=$$v},expression:"active_tab"}},_vm._l((_vm.$t('floors')),function(floor,index){return _c('v-tab',{key:index,staticClass:"text-h5 pa-5",on:{"click":function($event){return _vm.selectImage(
            floor.floorImage.url,
            floor.floorNum,
            floor.speech,
            floor.floorName
          )}}},[_vm._v(" "+_vm._s(floor.floorName)+" ")])}),1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-img',{attrs:{"src":_vm.url,"width":"79%"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }